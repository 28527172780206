import React from "react";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import {
  Divider,
  Container,
  Button,
  Select,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MainAppBar from "../../components/MainAppBar";
import { BarChart } from "../../components/BarChart";
import LoadingView from "../../components/loadingView";
import NoSubscriptionBanner from "../home/components/noSubscriptionBanner";
import DialogWarning from "../../components/dialogWarning";
import DisableToursPopover from "../../../utils/disableToursPopover";
import FloatingVideoLink from "../../components/floatingVideoLinks";
import LimitProgressIndicator from "../../../utils/limitProgressIndicator";

const AccountView = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <MainAppBar
        headerText={t("account_view.header_text")}
        showHomeButton={true}
        showAdminButton={props.state.isAdmin}
      />
      <NoSubscriptionBanner
        role={props.state.account?.role}
        hasTrial={props.state.account?.hasTrial}
        redirectToSelectedPack={props.state.account?.redirectToSelectedPack}
        classes={props.classes}
      ></NoSubscriptionBanner>
      <FloatingVideoLink role={props.state.account?.role}></FloatingVideoLink>
      <DisableToursPopover
        userOpenedPopover={props.state.isDisableToursOpened}
      />
      <Container className={props.classes.formContainer} maxWidth="lg">
        <form
          className={props.classes.form}
          autoComplete="off"
          onSubmit={props.submitForm}
        >
          <TextField
            required
            name="firstName"
            id="filled-required"
            defaultValue={props.state.account.firstName}
            label={t("account_view.first_name")}
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "firstNameNotLoaded"
                : "firstNameLoaded"
            }
          />
          <TextField
            required
            name="lastName"
            id="filled-required"
            defaultValue={props.state.account.lastName}
            label={t("account_view.last_name")}
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded ? "lastNameNotLoaded" : "lastNameLoaded"
            }
          />
          <br />
          <TextField
            disabled
            id="outlined-disabled"
            defaultValue={props.state.account.email}
            label={t("account_view.email")}
            variant="outlined"
            size="small"
            className={props.classes.textField}
            key={props.state.accountLoaded ? "emailNotLoaded" : "emailLoaded"}
          />
          <TextField
            required
            name="company"
            id="filled-required"
            defaultValue={props.state.account.company}
            label={t("account_view.company")}
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 35 }}
            key={
              props.state.accountLoaded
                ? "companyNotLoaded"
                : "companyNameLoaded"
            }
          />
          <br />
          <Link
            className={props.classes.passwordForgotten}
            to="/request-password-reset"
          >
            {t("account_view.change_password")}
          </Link>
          <br />
          <br />
          <Divider />
          <br />
          <TextField
            name="phoneNumber"
            defaultValue={props.state.account.phoneNumber}
            label={t("account_view.phone")}
            variant="outlined"
            size="small"
            type="tel"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "phoneNumberNotLoaded"
                : "phoneNumberLoaded"
            }
          />
          <TextField
            name="siret"
            defaultValue={props.state.account.siret}
            label={t("account_view.siret")}
            variant="outlined"
            size="small"
            type="number"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "siretNameNotLoaded"
                : "siretNameLoaded"
            }
          />
          <br />
          <TextField
            name="streetAddress"
            defaultValue={props.state.account.streetAddress}
            label={t("account_view.street_address")}
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded
                ? "streetAddressNotLoaded"
                : "streetAddressNameLoaded"
            }
          />
          <TextField
            name="city"
            defaultValue={props.state.account.city}
            label={t("account_view.city")}
            variant="outlined"
            size="small"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded ? "cityNameNotLoaded" : "cityNameLoaded"
            }
          />
          <TextField
            name="zipCode"
            defaultValue={props.state.account.zipCode}
            label={t("account_view.zip_code")}
            variant="outlined"
            size="small"
            type="number"
            onChange={props.updateForm}
            className={props.classes.textField}
            inputProps={{ maxLength: 25 }}
            key={
              props.state.accountLoaded ? "zipCodeNotLoaded" : "zipCodeLoaded"
            }
          />
          <br />
          <br />
          <Divider />
          <br />
          <br />
          <Box display="flex">
            <TextField
              required
              name="limitSessionPerMonth"
              id="filled-required"
              value={props.state.account.limitSessionPerMonth}
              label="Sessions max par mois"
              type="number"
              variant="outlined"
              size="medium"
              onChange={props.updateForm}
              className={props.classes.textField}
              style={{ backgroundColor: "white" }}
              InputProps={{
                inputProps: {
                  max: 1000,
                  min: props.state.account.maxSessionsPerMonth,
                },
              }}
              key={
                props.state.accountLoaded
                  ? "nbMaxPaidSessionsNotLoaded"
                  : "nbMaxPaidSessionsLoaded"
              }
            />
            <Box style={{ width: "50px" }}></Box>
            <LimitProgressIndicator
              value={
                props.state.account.analyticsCountMonth
                  ? props.state.account.analyticsCountMonth
                  : 0
              }
              freeSessionsLimit={props.state.account.maxSessionsPerMonth}
              maxSessionsLimit={props.state.account.limitSessionPerMonth}
            ></LimitProgressIndicator>
          </Box>

          <br />
          <Box style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Button
              variant="outlined"
              className={props.classes.saveButton}
              type="submit"
            >
              {t("account_view.save")}
            </Button>
          </Box>
          {props.state.showError ? (
            <Alert severity="error">{props.state.errorMessage}</Alert>
          ) : (
            <div />
          )}
          {props.state.showSuccess ? (
            <Alert severity="success">{props.state.successMessage}</Alert>
          ) : (
            <div />
          )}
          <br />
          <Divider />
          <br />
          <Grid>
            <Box item xs={6}>
              {props.state.account.role === "PACK_DECOUVERTE" ||
              props.state.account.role === "NO_SUBSCRIBTION" ? (
                <Typography className={props.classes.optionLocked}>
                  {t("account_view.leaderboard_disabled")}
                </Typography>
              ) : (
                <Typography className={props.classes.optionUnlocked}>
                  {t("account_view.leaderboard_enabled")}
                </Typography>
              )}
              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  {t("account_view.max_tours")}
                  <b>{props.state.account.maxNbOfTours}</b>
                </Typography>
              </Box>
              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  {t("account_view.max_zones_per_tour")}
                  <b>{props.state.account.maxZones}</b>
                </Typography>
              </Box>

              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  {t("account_view.max_enigmas_per_zone")}
                  <b>{props.state.account.maxEnigmas}</b>
                </Typography>
              </Box>
              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  {t("account_view.max_sessions")}
                  <b>{props.state.account.maxSessionsPerMonth}</b>
                </Typography>
              </Box>

              <Box className={props.classes.optionsContainer}>
                <Typography className={props.classes.accountOptionsText}>
                  {t("account_view.account_type")}
                  <b>
                    {props.state.account.role === "PACK_DECOUVERTE"
                      ? t("account_view.roles.pack_decouverte")
                      : props.state.account.role === "PACK_AVENTURE"
                      ? t("account_view.roles.pack_aventure")
                      : props.state.account.role === "PACK_EXPERT"
                      ? t("account_view.roles.pack_expert")
                      : props.state.account.role === "NO_SUBSCRIBTION"
                      ? t("account_view.roles.no_subscription")
                      : props.state.account.role === "ADMIN"
                      ? t("account_view.roles.admin")
                      : "Pas d'abonnement"}
                  </b>
                </Typography>
              </Box>
            </Box>

            <Box item xs={6}>
              <Link to={`/subscribe`}>
                {" "}
                <Button variant="contained" className={props.classes.updateAbo}>
                  {props.state.account.role === "NO_SUBSCRIBTION" ||
                  !props.state.account.stripeCustomerId
                    ? t("account_view.subscribe")
                    : t("account_view.change_subscription")}
                </Button>
              </Link>

              {props.state.account.stripeCustomerId && (
                <Button
                  variant="contained"
                  className={props.classes.updateAbo}
                  onClick={props.getCustomerPortalSession}
                >
                  {t("account_view.billing_info")}
                </Button>
              )}

              {props.state.account.role !== "NO_SUBSCRIBTION" &&
                props.state.account.stripeCustomerId && (
                  <Button
                    variant="contained"
                    className={props.classes.deleteAbo}
                    onClick={props.openDialog}
                  >
                    {t("account_view.cancel_subscription")}
                  </Button>
                )}
            </Box>
          </Grid>

          <DialogWarning
            open={props.state.dialogWaringOpened}
            primaryText={t("account_view.confirm_cancel_subscription_title")}
            secondaryText={t("account_view.confirm_cancel_subscription_text")}
            primaryAction={props.onCancelSubscription}
            closeAction={props.closeDialog}
            primaryButtonText={t("button.confirm")}
            secondaryButtonText={t("button.cancel")}
          />

          <br />

          <Divider />
          <br />

          <Box className={props.classes.optionsContainer}>
            <Typography className={props.classes.accountOptionsText}>
              {t("account_view.admin_code")}
              <b>{props.state.account.adminCode}</b>
            </Typography>
          </Box>
          <Box className={props.classes.optionsContainer}>
            <Button
              variant="outlined"
              className={props.classes.deactivateToursButton}
              disabled={props.isButtonDeactivatedToursDisabled()}
              onClick={() => {
                props.openDeactivatedTours(true);
              }}
            >
              {t("account_view.toggle_tours")}
            </Button>
            {props.state.account.setDisabledToursAt &&
              props.isButtonDeactivatedToursDisabled() && (
                <Typography className={props.classes.reactivateButtonText}>
                  {t("account_view.reactivate_tours")}{" "}
                  {props.getDateButtonToursDisabledReactivate()}.
                </Typography>
              )}
          </Box>

          {/* <TextField
            disabled
            id="outlined-disabled"
            defaultValue={props.state.account.adminCode}
            label="Code admin"
            variant="outlined"
            size="small"
            className={props.classes.textField}
            key={
              props.state.accountLoaded
                ? "adminCodeNotLoaded"
                : "adminCodeLoaded"
            }
          /> */}
          {/* <TextField
            disabled
            id="outlined-disabled"
            defaultValue={props.state.account.secretCode}
            label="Code secret"
            helperText="Il s'agit du code que vous devez rentrer dans vos IPads pour pouvoir récupérer vos parcours"
            variant="outlined"
            size="small"
            className={props.classes.textField}
            key={
              props.state.accountLoaded
                ? "secretCodeNotLoaded"
                : "secretCodeLoaded"
            }
          /> */}
          <br />
          <Divider />
          <br />
          <br />
          <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
            {t("account_view.statistics")}
          </Typography>
          <Select
            labelId="select-month-offset"
            id="select-month-offset"
            value={props.state.monthOffset ?? 999}
            onChange={props.updateMonthOffset}
          >
            <MenuItem value={999}>{t("account_view.all")}</MenuItem>
            <MenuItem value={3}>{t("account_view.three_months")}</MenuItem>
            <MenuItem value={6}>{t("account_view.six_months")}</MenuItem>
            <MenuItem value={12}>{t("account_view.one_year")}</MenuItem>
          </Select>
          {props.state.isAnalyticDatasReady ? (
            <BarChart chartData={props.state.analytics} />
          ) : (
            <>
              <br />
              <br />
              <CircularProgress />
            </>
          )}
        </form>
      </Container>
    </>
  );
};

export default AccountView;
