import React, { Component } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import AuthStorage from "../../../utils/authStorage";
import ApiUser from "../../../API/ApiUser";
import styles from "./signupStyles";
import SignupView from "./signupView";
import qs from "qs";
import { withTranslation } from "react-i18next";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      islogged: false,
      isValidMinLength: false,
      isValidSpecialChar: false,
      isValidNumber: false,
      redirectToSelectedPack: null,
      signupParams: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        repeatPassword: null,
        company: null,
        phoneNumber: null,
        hasAcceptedTerms: false,
      },
    };

    const { t } = this.props;

    this.passwordCriteria = {
      minLength: {
        regex: /.{6,}/,
        message: t("signup.password_criteria.min_length"),
      },
      specialChar: {
        regex: /[!@#$%^&*]/,
        message: t("signup.password_criteria.special_char"),
      },
      number: { regex: /[0-9]/, message: t("signup.password_criteria.number") },
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.signup = this.signup.bind(this);
  }

  componentDidMount() {
    document.title = "Inscription | Landing Zone";

    let redirectToSelectedPack = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).redirectToSelectedPack;
    this.setState((prevState) => ({
      ...prevState,
      redirectToSelectedPack: redirectToSelectedPack,
    }));
  }

  handleFormChange(event) {
    let signupParamsNew = { ...this.state.signupParams };
    const { name, type } = event.target;

    let val = type === "checkbox" ? event.target.checked : event.target.value;
    signupParamsNew[event.target.name] = val;

    if (event.target.name == "password") {
      const newValidations = {
        minLength: this.passwordCriteria.minLength.regex.test(val),
        specialChar: this.passwordCriteria.specialChar.regex.test(val),
        number: this.passwordCriteria.number.regex.test(val),
      };

      this.setState({
        isValidMinLength: newValidations.minLength,
        isValidSpecialChar: newValidations.specialChar,
        isValidNumber: newValidations.number,
      });
    }

    this.setState({
      signupParams: signupParamsNew,
    });
  }

  async signup(event) {
    event.preventDefault();
    const { t } = this.props;
    let firstName = this.state.signupParams.firstName;
    let lastName = this.state.signupParams.lastName;
    let email = this.state.signupParams.email;
    let password = this.state.signupParams.password;
    let repeatPassword = this.state.signupParams.repeatPassword;
    let company = this.state.signupParams.company;
    let phoneNumber = this.state.signupParams.phoneNumber;

    if (password != repeatPassword) {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: t("signup.error_messages.password_mismatch"),
        showError: true,
      }));
      return;
    }

    if (!this.state.signupParams.hasAcceptedTerms) {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: t("signup.error_messages.accept_terms"),
        showError: true,
      }));
      return;
    }

    const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
    if (!passwordRegex.test(password)) {
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: t("signup.error_messages.invalid_password"),
        showError: true,
      }));
      return;
    }

    let response = await ApiUser.signup(
      firstName,
      lastName,
      email,
      company,
      phoneNumber,
      password
    );

    if (response.status == 201) {
      window.location.href = "/check-your-mails";
    } else {
      console.log("response ", response);
      if (response.data === "tel-not-valid") {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: t("signup.error_messages.invalid_phone"),
          showError: true,
        }));
        return;
      } else if (response.data === "user-already-exists") {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: t("signup.error_messages.user_exists"),
          showError: true,
        }));
        return;
      }
      this.setState((prevState) => ({
        ...prevState,
        errorMessage: t("signup.error_messages.general_error"),
        showError: true,
      }));
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.islogged) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <SignupView
          {...this.props}
          state={this.state}
          handleFormChange={this.handleFormChange}
          signup={this.signup}
          passwordCriteria={this.passwordCriteria}
        />
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(withTranslation()(Signup))
);
