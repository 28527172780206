import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import { Redirect } from "react-router-dom";
import AccountIcon from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import DoneIcon from "@material-ui/icons/Done";
import { FileCopy } from "@material-ui/icons";
import MapIcon from "@material-ui/icons/Map";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  Divider,
  Container,
  Button,
  InputLabel,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
  CircularProgress,
  Popper,
  Paper,
  InputAdornment,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import qs from "qs";

import ApiTour from "../../API/ApiTour";
import ApiAdmin from "../../API/ApiAdmin";
import ApiUser from "../../API/ApiUser";
import DraggableList from "../components/draggableList";
import ListItemLink from "../components/listItemLink";
import DialogWarning from "../components/dialogWarning";
import Authenticated from "../components/authenticated";
import AuthStorage from "../../utils/authStorage";
import MainAppBar from "../components/MainAppBar";
import { validateAccount } from "../../utils/validateAccount";
import { BarChart } from "../components/BarChart";
import BarChartUtils from "../../utils/barChart";

import { apiHost } from "../../constants/ApiConstants";
import CodeListComponent from "../user/home/components/parcoursCodes/codeListComponent";
import ParcoursListComponent from "../user/home/components/parcoursList/parcoursListComponent";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // Utilise AdapterDateFns pour les objets Date natifs
import { deDE, frFR } from "@mui/x-date-pickers/locales";
import { fr } from "date-fns/locale";
import ApiGameAnalytics from "../../API/ApiGameAnalytics";
import { MapTilesStats } from "../components/MapTilesStats";
import MapTilesChartUtils from "../../utils/mapTilesChart";
import LimitProgressIndicator from "../../utils/limitProgressIndicator";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.darkGrey.main,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  optionUnlocked: {
    marginLeft: "12px",
    marginBottom: "6px",
    color: "green",
    fontWeight: 300,
    fontSize: "12px",
  },
  optionLocked: {
    marginLeft: "12px",
    marginBottom: "6px",
    color: "red",
    fontWeight: 300,
    fontSize: "12px",
  },
  formContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    width: "90%",
  },
  form: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "20ch",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
  },
  textfield: {
    minWidth: 200,
    backgroundColor: "red",
  },
  textfieldLong: {
    minWidth: 400,
    backgroundColor: "red",
  },
  list: {
    backgroundColor: theme.palette.lightGrey.main,
    borderRadius: 5,
    listStyle: "none",
  },
  listContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
  },
  buttonListPlus: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    color: "white",
  },
  returnButtonLink: {
    textDecoration: "none",
    margin: theme.spacing(2),
  },
  danger: {
    color: "red",
  },
  searchInput: {
    backgroundColor: "white",
    borderRadius: 12,
    marginBottom: 32,
    width: "100%",
  },
  pannel: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,
  },
  saveButton: {
    backgroundColor: theme.palette.orange.main,
    color: "#ffffff",
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 8,
    height: 40,
    marginTop: 9,
    marginBottom: 9,

    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  mainButtonAction: {
    color: "#ffffff",
    backgroundColor: theme.palette.orange.main,
    borderRadius: 24,
    fontWeight: 900,
    "&:hover": {
      backgroundColor: theme.palette.orange.dark,
    },
    marginBottom: 12,
  },
});

class ManageAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDateTilesRequest: null,
      endDateTilesRequest: null,
      tilesRequestDataset: null,
      totalTilesRequests: null,
      hoverChart: null,
      selection: [{ series: "", point: 0 }],
      tooltipTarget: null,
      tooltipEnabled: true,
      userId: -1,
      toursLoaded: false,
      analyticsLoaded: false,
      isAdmin: false,
      isAdminSet: false,
      showError: false,
      errorMessage: "",
      showSuccess: false,
      successMessage: "",
      accountLoaded: false,
      adminTours: [],
      searchQuery: "",
      filteredAdminTours: [],
      suggestionsOpened: false,
      anchorEl: null,
      adminTourSelectedForDuplication: null,
      toursReload: false,
      account: {
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phoneNumber: "",
        createdAt: "",
        streetAddress: "",
        city: "",
        zipCode: -1,
        siret: "",
        maxNbOfTours: -1,
        maxSessionsPerMonth: -1,
        limitSessionPerMonth: -1,
        maxZones: null,
        maxEnigmas: null,
        adminCode: "",
        role: null,
        analyticsCountMonth: null,
      },
      tours: [
        {
          name: "",
          createdAt: "",
          isValid: false,
          orderId: 0,
          id: 0,
        },
      ],
      secretCode: "",
      analyticsFromApi: null,
      analytics: { datasets: [] },
      monthOffset: 999,
      tourNames: [],
      isAnalyticDatasReady: false,
    };

    this.submitForm = this.submitForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.updateMonthOffset = this.updateMonthOffset.bind(this);
    this.updateStartDateTilesRequests =
      this.updateStartDateTilesRequests.bind(this);
    this.updateEndDateTilesRequests =
      this.updateEndDateTilesRequests.bind(this);
    this.onTourSelection = this.onTourSelection.bind(this);
    this.setSearchQuery = this.setSearchQuery.bind(this);
    this.filterAdminTours = this.filterAdminTours.bind(this);
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
    this.duplicateAdminTourOnUserAccount =
      this.duplicateAdminTourOnUserAccount.bind(this);

    this.changeHover = (hoverChart) => this.setState({ hoverChart });

    this.click = ({ targets }) => {
      const target = targets[0];
      if (target) {
        this.setState(({ selection }) => ({
          selection:
            selection[0] && compareTargets(selection[0], target)
              ? []
              : [target],
        }));
      }
    };
  }

  componentDidMount() {
    document.title = "Compte client | Landing Zone";

    let paramUserId = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).id;
    if (!paramUserId) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage:
          "Une erreur est survenue. Impossible de trouver les données liées à cette utilisateur",
        showSuccess: false,
      }));

      return;
    }

    this.setAdminTours();

    this.setState((prevState) => ({
      ...prevState,
      userId: paramUserId,
    }));

    this.toggleTooltip = () =>
      this.setState(({ tooltipEnabled }) => ({
        tooltipEnabled: !tooltipEnabled,
        tooltipTarget: null,
      }));

    this.getAnalyticsCountMonth(paramUserId);
    this.setStateIsAdmin();
    this.getAccount(paramUserId);
    this.getTours(paramUserId);
    this.getAnalytics(paramUserId);
  }

  async getAnalyticsCountMonth(userId) {
    try {
      const res = await ApiGameAnalytics.getAnalyticsCountUserMonth(userId);
      console.log("res : ", res);
      if (res.status === 200) {
        this.setState((prevState) => ({
          ...prevState,
          account: {
            ...prevState.account,
            analyticsCountMonth: res.data.count,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async setAdminTours() {
    const adminToursRes = await ApiTour.getTours();
    console.log("admin tours : ", adminToursRes);
    if (adminToursRes.status == 200 && adminToursRes.data) {
      this.setState((prevState) => ({
        ...prevState,
        adminTours: adminToursRes.data,
      }));
      this.setSearchQuery("");
    }
  }

  async duplicateAdminTourOnUserAccount() {
    if (
      this.state.adminTourSelectedForDuplication &&
      this.state.adminTourSelectedForDuplication.id
    ) {
      const res = await ApiAdmin.duplicateAdminTourOnUserAccount(
        this.state.adminTourSelectedForDuplication.id,
        this.state.userId
      );
      if (res.status !== 201) {
        if (res.status === 403) {
          this.setState((prevState) => ({
            ...prevState,
            showError: true,
            errorMessage:
              "Le client à atteint le nombre maximum de parcours autorisé",
            showSuccess: false,
            dialogWaringOpened: false,
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            showError: true,
            errorMessage:
              "Il y a eu une erreur lors de l'importation du parcours",
            showSuccess: false,
            dialogWaringOpened: false,
          }));
        }
      } else {
        this.getTours(this.state.userId);
        this.setState((prevState) => ({
          ...prevState,
          showSuccess: true,
          showError: false,
          successMessage: "Le parcours à été dupliqué",
          dialogWaringOpened: false,
        }));
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: "Veuillez séléctionner un parcours à dupliquer",
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  setAnchorEl(anchorEl) {
    this.setState((prevState) => ({
      ...prevState,
      anchorEl: anchorEl,
    }));
  }

  setSuggetionOppened(value) {
    this.setState((prevState) => ({
      ...prevState,
      suggestionsOpened: value,
    }));
  }

  handleSearchChange(event) {
    const value = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      adminTourSelectedForDuplication: null,
    }));

    this.setSearchQuery(value);
    if (value) {
      this.setSuggetionOppened(true);
    } else {
      this.setSuggetionOppened(false);
    }
  }

  handleSuggestionClick(tourSuggestion) {
    console.log("tourSuggestion 1 : ", tourSuggestion);

    if (tourSuggestion && tourSuggestion.title) {
      this.setSearchQuery(tourSuggestion.title);
      console.log("tourSuggestion.title : ", tourSuggestion.title);
      this.setState((prevState) => ({
        ...prevState,
        suggestionsOpened: false,
        searchQuery: tourSuggestion.title,
        adminTourSelectedForDuplication: tourSuggestion,
      }));
    }
  }

  setSearchQuery(query) {
    this.setState((prevState) => ({
      ...prevState,
      searchQuery: query,
    }));
    this.filterAdminTours(query);
  }

  filterAdminTours(query) {
    console.log("query ", query);
    if (!this.state.adminTours) {
      return;
    }
    console.log("this.state.adminTours ", this.state.adminTours);
    if (!query) {
      this.setState((prevState) => ({
        ...prevState,
        filteredAdminTours: this.state.adminTours,
      }));
    } else {
      console.log("ok");
      const filteredAdminTours = this.state.adminTours.filter((d) =>
        d.title?.toLowerCase().includes(query)
      );
      this.setState((prevState) => ({
        ...prevState,
        filteredAdminTours: filteredAdminTours,
      }));
    }
  }

  async updateMonthOffset(event) {
    let target = event.target;
    let value = target.value;

    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(
      this.state.analyticsFromApi,
      this.state.tourNames,
      value
    );

    this.setState((prevState) => ({
      ...prevState,
      monthOffset: value,
      analytics: analyticsDatasFormatted,
    }));
  }

  async getTotalTilesRequest(dataset) {
    const total = dataset.reduce((accumulator, currentValue) => {
      console.log("accumulator ", accumulator);
      console.log("currentValue ", currentValue);
      return accumulator + currentValue.tilesRequests.length;
    }, 0);
    console.log("total ", total);
    this.setState((prevState) => ({
      ...prevState,
      totalTilesRequests: total,
    }));
  }

  async updateTilesRequestDataset() {
    if (!this.state.startDateTilesRequest || !this.state.endDateTilesRequest) {
      console.log("start : ", this.state.startDateTilesRequest);
      console.log("end : ", this.state.endDateTilesRequest);
      return;
    }
    const datasetRes = await ApiGameAnalytics.getMapTilesRequests(
      this.state.userId,
      this.state.startDateTilesRequest,
      this.state.endDateTilesRequest
    );
    console.log("before formatDataForLineChart");
    const datasetFormatted = MapTilesChartUtils.formatDataForLineChart(
      datasetRes.data,
      this.state.startDateTilesRequest,
      this.state.endDateTilesRequest
    );
    this.setState((prevState) => ({
      ...prevState,
      tilesRequestDataset: datasetFormatted,
    }));
    this.getTotalTilesRequest(datasetRes.data);
    console.log("datasetFormatted : ", datasetFormatted);
  }

  async updateStartDateTilesRequests(date) {
    const currentTime = new Date();
    date.setHours(currentTime.getHours());
    date.setMinutes(currentTime.getMinutes());
    this.setState(
      (prevState) => ({
        ...prevState,
        startDateTilesRequest: date,
      }),
      () => {
        this.updateTilesRequestDataset();
      }
    );
  }

  async updateEndDateTilesRequests(date) {
    const currentTime = new Date();
    date.setHours(currentTime.getHours());
    date.setMinutes(currentTime.getMinutes());
    this.setState(
      (prevState) => ({
        ...prevState,
        endDateTilesRequest: date,
      }),
      () => {
        this.updateTilesRequestDataset();
      }
    );
  }

  async getAnalytics(userId) {
    let analytics = await ApiAdmin.getAccountAnalytics(userId);

    if (analytics.status != 200 || !analytics.data) {
      return;
    }

    let analyticsDatas = [];
    let tourNames = [];

    const tours = await ApiAdmin.getToursClient(userId);

    for (var i in analytics.data) {
      let tour = tours.data.find((tour) => tour.id == analytics.data[i].TourId);
      let tourName = "";
      if (tour) {
        tourName = tour.title;
      }
      if (!tourNames.includes(tourName) && tourName != "") {
        tourNames.push(tourName);
      }

      analyticsDatas.push({
        tourName: tourName,
        id: analytics.data[i].id,
        createdAt: analytics.data[i].created_at,
        score: analytics.data[i].score,
        distanceTraveled: analytics.data[i].distanceTraveled,
      });
    }

    let analyticsDatasFormatted = await BarChartUtils.formatDatasChart(
      analyticsDatas,
      tourNames,
      null
    );

    this.setState((prevState) => ({
      ...prevState,
      analyticsLoaded: true,
      analytics: analyticsDatasFormatted,
      analyticsFromApi: analyticsDatas,
      tourNames: tourNames,
      isAnalyticDatasReady: true,
    }));
  }

  async getTours(userId) {
    ApiAdmin.getToursClient(userId).then((tours) => {
      var dateOptions = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      };

      let toursDatas = [];

      console.log("tours : ", tours);

      if (tours.status !== 200 || !tours.data) return;
      console.log("tours data : ", tours.data);

      tours.data?.forEach((elem, i) => {
        toursDatas.push({
          name: elem.title,
          createdAt: new Date(elem.createdAt).toLocaleDateString(
            "fr",
            dateOptions
          ),
          id: elem.id,
          isValid: elem.isValid,
        });
      });

      this.setState((prevState) => ({
        ...prevState,
        toursLoaded: true,
        tours: toursDatas,
        toursReload: !this.state.toursReload,
      }));
    });
  }

  async getAccount(userId) {
    ApiAdmin.getUserAccount(userId).then((response) => {
      if (response.status != 200 || !response.data) {
        this.setState((prevState) => ({
          ...prevState,
          showError: true,
          showSuccess: false,
          errorMessage:
            "Impossible de récupérer de données sur cette utilisateur",
        }));

        return;
      }
      let address = ["", "", ""];
      if (response.data.address) {
        address = response.data.address.split(";");
      }

      if (!address || address.length != 3) {
        address.push("");
        address.push("");
      }

      console.log("response.data.role -> ", response.data.role);

      this.setState((prevState) => ({
        ...prevState,
        accountLoaded: true,
        account: {
          firstName: response.data.firstname,
          lastName: response.data.lastname,
          company: response.data.companyName,
          phoneNumber: response.data.phoneNumber,
          createdAt: response.data.createdAt,
          streetAddress: address[0],
          city: address[1],
          zipCode: Number(address[2]) | 0,
          siret: response.data.companySiret,
          email: response.data.email,
          maxNbOfTours: response.data.maxTours,
          maxSessionsPerMonth: response.data.maxSessionsPerMonth,
          secretCode: response.data.secretCodeIPad,
          adminCode: response.data.adminCode,
          role: response.data.role,
          maxZones: response.data.maxZones,
          maxEnigmas: response.data.maxEnigmas,
          limitSessionPerMonth: response.data.limitSessionPerMonth,
        },
      }));
    });
  }

  async submitForm(event) {
    event.preventDefault();

    this.updateProfile();
  }

  async updateProfile() {
    if (
      !this.state.account.firstName ||
      this.state.account.firstName == "" ||
      !this.state.account.lastName ||
      this.state.account.lastName == "" ||
      !this.state.account.company ||
      this.state.account.company == ""
    ) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: "Veuillez remplire tout les champs",
        showSuccess: false,
        dialogWaringOpened: false,
      }));

      return;
    }

    try {
      validateAccount(
        "parameternotsent@gmail.com",
        this.state.account.siret,
        this.state.account.phoneNumber,
        this.state.account.maxNbOfTours,
        this.state.account.maxSessionsPerMonth,
        this.state.account.limitSessionPerMonth
      );
    } catch (err) {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: err.message,
        showSuccess: false,
        dialogWaringOpened: false,
      }));

      return;
    }

    let addr = null;
    if (
      this.state.account.streetAddress &&
      this.state.account.city &&
      this.state.account.zipCode
    ) {
      addr =
        this.state.account.streetAddress +
        ";" +
        this.state.account.city +
        ";" +
        this.state.account.zipCode;
    }
    let response = await ApiAdmin.updateUserAccount(
      this.state.userId,
      this.state.account.firstName,
      this.state.account.lastName,
      this.state.account.company,
      addr,
      this.state.account.siret,
      this.state.account.phoneNumber,
      this.state.account.maxNbOfTours,
      this.state.account.maxZones,
      this.state.account.maxEnigmas,
      this.state.account.role,
      this.state.account.maxSessionsPerMonth,
      this.state.account.limitSessionPerMonth
    );

    if (response.status == 200 && response.data) {
      this.setState((prevState) => ({
        ...prevState,
        showError: false,
        showSuccess: true,
        successMessage: "Le compte à bien été mis à jour",
        dialogWaringOpened: false,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showError: true,
        errorMessage: "Une erreur est survenu",
        showSuccess: false,
        dialogWaringOpened: false,
      }));
    }
  }

  async setStateIsAdmin() {
    let response = await ApiUser.isAdmin();

    if (response.status == 200) {
      this.setState((prevState) => ({
        ...prevState,
        isAdmin: response.data.isAdmin,
        isAdminSet: true,
      }));
    }
  }

  getLimitsForRole(role) {
    switch (role) {
      case "PACK_DECOUVERTE":
        return {
          maxNbOfTours: 1,
          maxZones: 15,
          maxEnigmas: 5,
          maxSessionsPerMonth: 15,
          limitSessionPerMonth: 100,
        };
      case "PACK_AVENTURE":
        return {
          maxNbOfTours: 3,
          maxZones: 30,
          maxEnigmas: 15,
          maxSessionsPerMonth: 40,
          limitSessionPerMonth: 200,
        };
      case "PACK_EXPERT":
        return {
          maxNbOfTours: 10,
          maxZones: 30,
          maxEnigmas: 15,
          maxSessionsPerMonth: 100,
          limitSessionPerMonth: 500,
        };
      case "NO_SUBSCRIBTION":
        return {
          maxNbOfTours: 0,
          maxZones: 0,
          maxEnigmas: 0,
          maxSessionsPerMonth: 10,
          limitSessionPerMonth: 10,
        };
      case "ADMIN":
        return {
          maxNbOfTours: 99,
          maxZones: 99,
          maxEnigmas: 99,
          maxSessionsPerMonth: 1000,
          limitSessionPerMonth: 1000,
        };
      default:
        return {
          maxNbOfTours: 0,
          maxZones: 0,
          maxEnigmas: 0,
          maxSessionsPerMonth: 10,
          limitSessionPerMonth: 10,
        };
    }
  }

  updateForm(event) {
    let target = event.target;
    let value = target.value;

    if (target.name == "zipCode") {
      value = Number(target.value);
    }

    this.setState((prevState) => ({
      ...prevState,
      account: {
        ...prevState.account,
        [target.name]: value,
      },
    }));

    if (target.name == "role") {
      let limits = this.getLimitsForRole(target.value);
      console.log("set limits : ", limits);
      this.setState((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          maxNbOfTours: limits.maxNbOfTours,
          maxZones: limits.maxZones,
          maxEnigmas: limits.maxEnigmas,
          maxSessionsPerMonth: limits.maxSessionsPerMonth,
          limitSessionPerMonth: limits.limitSessionPerMonth,
        },
      }));
    }
  }

  onTourSelection(tourId) {
    this.setState((prevState) => ({
      ...prevState,
      tourIdSelected: tourId,
    }));
  }

  render() {
    const { classes } = this.props;

    if (!this.state.isAdmin && !this.state.isAdminSet) {
      return <div></div>;
    } else if (!this.state.isAdmin && this.state.isAdminSet) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    let toursItems = [];
    if (this.state.tours) {
      this.state.tours.forEach((tour) => {
        let item = {
          idInt: tour.id,
          id: `tour-${tour.id}`,
          primary: tour.name,
          secondary: tour.createdAt,
          to: `/parcours?id=${tour.id}`,
          icon: <MapIcon />,
          isValid: tour.isValid,
        };

        toursItems.push(item);
      });
    }

    return (
      <>
        <MainAppBar
          headerText="Compte client"
          showHomeButton={true}
          showAdminButton={this.state.isAdmin}
        />
        <Container className={classes.formContainer} maxWidth={false}>
          <form
            className={classes.form}
            autoComplete="off"
            onSubmit={this.submitForm}
          >
            <TextField
              required
              name="firstName"
              id="filled-required"
              defaultValue={this.state.account.firstName}
              label="Prénom"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded
                  ? "firstNameNotLoaded"
                  : "firstNameLoaded"
              }
            />
            <TextField
              required
              name="lastName"
              id="filled-required"
              defaultValue={this.state.account.lastName}
              label="Nom"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded
                  ? "lastNameNotLoaded"
                  : "lastNameLoaded"
              }
            />
            <br />
            <TextField
              disabled
              id="outlined-disabled"
              defaultValue={this.state.account.email}
              label="Email"
              variant="outlined"
              size="small"
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              key={this.state.accountLoaded ? "emailNotLoaded" : "emailLoaded"}
            />
            <TextField
              required
              name="company"
              id="filled-required"
              defaultValue={this.state.account.company}
              label="Entreprise"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 35 }}
              key={
                this.state.accountLoaded
                  ? "companyNotLoaded"
                  : "companyNameLoaded"
              }
            />
            <br />
            <br />
            <Divider />
            <br />
            <TextField
              name="phoneNumber"
              defaultValue={this.state.account.phoneNumber}
              label="Téléphone"
              variant="outlined"
              size="small"
              type="tel"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded
                  ? "phoneNumberNotLoaded"
                  : "phoneNumberLoaded"
              }
            />
            <TextField
              name="siret"
              defaultValue={this.state.account.siret}
              label="Siret"
              variant="outlined"
              size="small"
              type="number"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded
                  ? "siretNameNotLoaded"
                  : "siretNameLoaded"
              }
            />
            <br />
            <TextField
              name="streetAddress"
              defaultValue={this.state.account.streetAddress}
              label="Rue"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded
                  ? "streetAddressNotLoaded"
                  : "streetAddressNameLoaded"
              }
            />
            <TextField
              name="city"
              defaultValue={this.state.account.city}
              label="Ville"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded
                  ? "cityNameNotLoaded"
                  : "cityNameLoaded"
              }
            />
            <TextField
              name="zipCode"
              defaultValue={this.state.account.zipCode}
              label="Code postal"
              variant="outlined"
              size="small"
              type="number"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 25 }}
              key={
                this.state.accountLoaded ? "zipCodeNotLoaded" : "zipCodeLoaded"
              }
            />

            <br />
            <Divider />
            <br />
            {this.state.account.role === "PACK_DECOUVERTE" ? (
              <Typography className={classes.optionLocked}>
                Leaderboard désactivé
              </Typography>
            ) : (
              <Typography className={classes.optionUnlocked}>
                Leaderboard activé
              </Typography>
            )}
            <TextField
              required
              name="maxNbOfTours"
              id="filled-required"
              value={this.state.account.maxNbOfTours}
              label="Parcours max"
              type="number"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              key={
                this.state.accountLoaded
                  ? "nbMaxToursNotLoaded"
                  : "nbMaxToursLoaded"
              }
            />
            <TextField
              required
              name="maxZones"
              id="outlined-number"
              label="Zones max"
              variant="outlined"
              size="small"
              value={this.state.account.maxZones}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 100,
                  min: 0,
                },
              }}
              style={{ backgroundColor: "white" }}
              key={
                this.state.accountLoaded ? "nbZonesNotLoaded" : "nbZonesLoaded"
              }
            />
            <TextField
              required
              name="maxEnigmas"
              id="outlined-number"
              label="Énigmes max"
              variant="outlined"
              size="small"
              value={this.state.account.maxEnigmas}
              onChange={this.updateForm}
              className={classes.textField}
              type="number"
              InputProps={{
                inputProps: {
                  max: 100,
                  min: 0,
                },
              }}
              style={{ backgroundColor: "white" }}
              key={
                this.state.accountLoaded
                  ? "nbEnigmasNotLoaded"
                  : "nbEnigmasLoaded"
              }
            />
            <br />
            <TextField
              required
              name="maxSessionsPerMonth"
              id="filled-required"
              value={this.state.account.maxSessionsPerMonth}
              label="Sessions gratuites par mois"
              type="number"
              variant="outlined"
              size="medium"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              key={
                this.state.accountLoaded
                  ? "nbMaxSessionsNotLoaded"
                  : "nbMaxSessionsLoaded"
              }
            />
            <TextField
              required
              name="limitSessionPerMonth"
              id="filled-required"
              value={this.state.account.limitSessionPerMonth}
              label="Sessions max par mois"
              type="number"
              variant="outlined"
              size="medium"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              InputProps={{
                inputProps: {
                  max: 1000,
                  min: this.state.account.maxSessionsPerMonth,
                },
              }}
              key={
                this.state.accountLoaded
                  ? "nbMaxPaidSessionsNotLoaded"
                  : "nbMaxPaidSessionsLoaded"
              }
            />
            <LimitProgressIndicator
              value={
                this.state.account.analyticsCountMonth
                  ? this.state.account.analyticsCountMonth
                  : 0
              }
              freeSessionsLimit={this.state.account.maxSessionsPerMonth}
              maxSessionsLimit={this.state.account.limitSessionPerMonth}
            ></LimitProgressIndicator>
            <InputLabel
              id="select-role"
              style={{ marginTop: 6, marginLeft: 6 }}
            >
              Type de compte *
            </InputLabel>
            <Select
              style={{ marginLeft: 6 }}
              name="role"
              labelId="select-response-type"
              id="select-response-type"
              value={this.state.account.role}
              onChange={this.updateForm}
            >
              <MenuItem value={"NO_SUBSCRIBTION"}>Pas d'abonnement</MenuItem>
              <MenuItem value={"PACK_DECOUVERTE"}>Pack découverte</MenuItem>
              <MenuItem value={"PACK_AVENTURE"}>Pack aventure</MenuItem>
              <MenuItem value={"PACK_EXPERT"}>Pack expert</MenuItem>
              <MenuItem value={"ADMIN"} className={classes.danger}>
                Administrateur
              </MenuItem>
            </Select>
            <br />

            <DialogWarning
              open={this.state.dialogWaringOpened}
              primaryText="Voulez vous vraiment donner à cette utilisateur le role d'administrateur"
              secondaryText="Cette utilisateur aura la possibilité d'éditer et de supprimer tout les comptes ainsi que tout les parcours."
              primaryAction={this.signup}
              closeAction={this.closeDialog}
              primaryButtonText="Valider"
              secondaryButtonText="Annuler"
            />
            <br />
            <Divider />
            <br></br>
            <TextField
              disabled
              id="outlined-disabled"
              name="adminCode"
              defaultValue={this.state.account.adminCode}
              label="Code admin"
              variant="outlined"
              size="small"
              onChange={this.updateForm}
              className={classes.textField}
              style={{ backgroundColor: "white" }}
              key={
                this.state.accountLoaded
                  ? "adminCodeNotLoaded"
                  : "adminCodeToursLoaded"
              }
            />
            {/* <TextField
              disabled
              id="outlined-disabled"
              defaultValue={this.state.account.secretCode}
              label="Code secret"
              helperText="Il s'agit du code que l'utilisateur doit rentrer dans ses IPads pour pouvoir récupérer ses parcours"
              variant="outlined"
              size="small"
              className={classes.textField}
              key={
                this.state.accountLoaded
                  ? "secretCodeNotLoaded"
                  : "secretCodeLoaded"
              }
            /> */}
            <br />
            <br />
            <Divider />

            <Box style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Button
                variant="outlined"
                className={classes.saveButton}
                color="primary"
                type="submit"
              >
                ENREGISTRER
              </Button>
            </Box>

            <br />
            {this.state.showError ? (
              <Alert severity="error">{this.state.errorMessage}</Alert>
            ) : (
              <div />
            )}
            {this.state.showSuccess ? (
              <Alert severity="success">{this.state.successMessage}</Alert>
            ) : (
              <div />
            )}

            <br />
            <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
              Parcours de l'utilisateur
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box
                  className={`${classes.pannel}`}
                  sx={{ p: 2, mt: 2, ml: 2 }}
                >
                  {this.state.userId !== -1 ? (
                    <>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Link
                          to={`/parcours?clientUserId=${this.state.userId}`}
                        >
                          <Button
                            variant="contained"
                            className={classes.mainButtonAction}
                          >
                            Nouveau parcours
                          </Button>
                        </Link>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 20,
                            width: "100%",
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Dupliquer un parcours"
                            variant="outlined"
                            style={{
                              width: "100%",
                            }}
                            className={classes.searchInput}
                            value={this.state.searchQuery}
                            onChange={(event) => this.handleSearchChange(event)}
                            onClick={(event) => {
                              this.setAnchorEl(event.currentTarget);
                              this.setSuggetionOppened(true);
                            }}
                            onBlur={(event) => this.setSuggetionOppened(false)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      this.duplicateAdminTourOnUserAccount()
                                    }
                                    edge="end"
                                  >
                                    <FileCopy />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Popper
                            open={this.state.suggestionsOpened}
                            anchorEl={this.state.anchorEl}
                            placement="bottom-start"
                            transition
                          >
                            <Paper>
                              <List>
                                {this.state.filteredAdminTours?.map(
                                  (suggestion, index) => (
                                    <ListItem
                                      button
                                      key={index}
                                      onMouseDown={(event) => {
                                        console.log("in the on click");
                                        this.handleSuggestionClick(suggestion);
                                      }}
                                    >
                                      <ListItemText
                                        primary={suggestion.title}
                                      />
                                    </ListItem>
                                  )
                                )}
                              </List>
                            </Paper>
                          </Popper>
                        </Box>
                      </Box>
                      <ParcoursListComponent
                        {...this.props}
                        isAdminView={true}
                        clientId={this.state.userId}
                        onTourSelection={this.onTourSelection}
                        tourIdSelected={this.state.tourIdSelected}
                        toursReload={this.state.toursReload}
                      ></ParcoursListComponent>
                    </>
                  ) : (
                    <div></div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  className={`${classes.pannel}`}
                  sx={{ p: 2, mt: 2, mr: 2 }}
                >
                  <CodeListComponent
                    tourId={this.state.tourIdSelected}
                  ></CodeListComponent>
                </Box>
              </Grid>
            </Grid>
            <br />
            <br />
            <Divider />
            <br />

            <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
              Sessions
            </Typography>

            <Select
              labelId="select-month-offset"
              id="select-month-offset"
              value={this.state.monthOffset ?? 999}
              onChange={this.updateMonthOffset}
            >
              <MenuItem value={999}>Tout</MenuItem>
              <MenuItem value={3}>3 mois</MenuItem>
              <MenuItem value={6}>6 mois</MenuItem>
              <MenuItem value={12}>1 an</MenuItem>
            </Select>

            {this.state.isAnalyticDatasReady ? (
              <BarChart chartData={this.state.analytics} />
            ) : (
              <>
                <br />
                <br />
                <CircularProgress />
              </>
            )}
            <br />
            <br />
            <Divider />
            <br />

            <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
              Requêtes de tuiles
            </Typography>

            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={fr}
              localeText={
                frFR.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <div style={{ display: "flex" }}>
                {/* DatePicker pour la date de début */}
                <DatePicker
                  label="Date de début"
                  value={this.state.startDateTilesRequest}
                  onChange={(date) => this.updateStartDateTilesRequests(date)}
                  maxDate={new Date()} // Limite la sélection à aujourd'hui
                  renderInput={(params) => <TextField {...params} />}
                />

                {/* Spacer pour séparer les deux pickers */}
                <div style={{ width: 20 }}></div>

                {/* DatePicker pour la date de fin */}
                <DatePicker
                  label="Date de fin"
                  value={this.state.endDateTilesRequest}
                  onChange={(date) => this.updateEndDateTilesRequests(date)}
                  minDate={this.state.startDateTilesRequest || new Date()}
                  maxDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              {this.state.tilesRequestDataset ? (
                <>
                  {this.state.totalTilesRequests ? (
                    <>
                      <strong>Total : {this.state.totalTilesRequests}</strong>
                      <br></br>
                      <strong>
                        Prix AWS :{" "}
                        {(this.state.totalTilesRequests / 100) * 0.04}$
                      </strong>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  <MapTilesStats
                    lineData={this.state.tilesRequestDataset}
                  ></MapTilesStats>
                </>
              ) : (
                <>
                  <br />
                </>
              )}
            </LocalizationProvider>
          </form>
        </Container>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ManageAccount);
