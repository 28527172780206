import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const LimitProgressIndicator = (props) => {
  const { value, freeSessionsLimit, maxSessionsLimit } = props;
  let freeSessionWidth = (freeSessionsLimit / maxSessionsLimit) * 50; // Proportion pour les free sessions (50% de l'espace total)
  let paidSessionWidth =
    ((maxSessionsLimit - freeSessionsLimit) / maxSessionsLimit) * 50;

  if (freeSessionWidth < 0 || paidSessionWidth < 0) {
    freeSessionWidth = 25;
    paidSessionWidth = 25;
  }

  return (
    // <Box display="flex" flexDirection="row" alignItems="center">
    <Box style={{ width: "100%" }}>
      <Box display={"flex"} width="100%">
        <Typography
          style={{
            width: `${freeSessionWidth}%`,
            fontSize: "12px",
          }}
        >
          {Math.min(value, freeSessionsLimit)}/{freeSessionsLimit} sessions
          gratuites
        </Typography>
        <Typography
          style={{
            width: "20%",
            fontSize: "12px",
            width: `${paidSessionWidth}%`,
          }}
        >
          {value - freeSessionsLimit > 0 ? value - freeSessionsLimit : 0}/
          {maxSessionsLimit - freeSessionsLimit} Sessions payantes
        </Typography>
      </Box>

      <Box
        width="100%"
        position="relative"
        display="flex"
        flexDirection="row"
        //   gap={2}
        alignItems="center"
      >
        {/* Barre bleue pour la partie avant maxSessionsLimit */}
        <LinearProgress
          variant="determinate"
          value={(Math.min(value, freeSessionsLimit) / freeSessionsLimit) * 100}
          style={{
            height: 10,
            backgroundColor: "#add8e6", // Bleu clair pour l'arrière-plan
            width: `${freeSessionWidth}%`,
          }}
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#3f51b5", // Bleu pour la partie remplie
            },
          }}
        />
        <Box
          sx={{
            height: "20px", // Hauteur du trait
            width: "2px", // Largeur du trait (épaisseur)
            backgroundColor: "grey", // Couleur du trait
            margin: "0 0", // Marges autour du trait
          }}
        />
        {/* Barre rouge pour la partie après maxSessionsLimit */}
        <LinearProgress
          variant="determinate"
          value={
            (Math.min(
              value - freeSessionsLimit,
              maxSessionsLimit - freeSessionsLimit
            ) /
              (maxSessionsLimit - freeSessionsLimit)) *
            100
          }
          style={{
            //   position: "absolute",
            //   left: `${(maxSessionsLimit / 100) * 100}%`, // Commence à maxSessionsLimit
            width: `${paidSessionWidth}%`,
            // width: `${100 - maxSessionsLimit}%`,
            backgroundColor: "#add8e6", // Bleu clair pour l'arrière-plan
            height: 10,
          }}
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#3f51b5", // Rouge pour la partie remplie après maxSessionsLimit
            },
          }}
        />
        {/* </Box> */}
        {/* <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${value} / ${maxSessionsLimit}`}
        </Typography>
      </Box> */}
      </Box>
    </Box>
  );
};

export default LimitProgressIndicator;
