import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { Check } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import { apiHost } from "../../../constants/ApiConstants";
import { useTranslation } from "react-i18next";

const SignupView = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <AppBar className={props.classes.appBar}>
        <Card className={props.classes.card}>
          <CardMedia
            component="img"
            alt="Description de l'image"
            height="40"
            width="40"
            image="/logo192.png"
            title="Titre de l'image"
          />
        </Card>
      </AppBar>
      <Box
        className={props.classes.backgroundImg}
        sx={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
          // height: "100vh",
        }}
      >
        <div className="signupForm">
          <Typography
            style={{
              marginTop: 150,
              marginBottom: 18,
              textAlign: "center",
              fontSize: 18,
              fontWeight: 600,
              color: "#151B2F",
            }}
            sx={{
              color: "#151B2F",
              // color: "primary.main",
            }}
          >
            {t("signup.more_info")}{" "}
            <a
              style={{ color: "#768FDD" }}
              href="https://landingzone.fr"
              target="_blank"
            >
              Landing Zone
            </a>
          </Typography>
          <Box
            className={props.classes.container}
            sx={{
              bgcolor: "primary.main",
            }}
          >
            <form
              onSubmit={props.signup}
              className={props.classes.formContainer}
            >
              <h1>{t("signup.submit")}</h1>
              <div>
                <div>
                  <TextField
                    required
                    name="firstName"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.first_name")}
                    className={props.classes.textfield}
                    inputProps={{ maxLength: 25 }}
                  />
                  <br />
                  <TextField
                    required
                    name="lastName"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.last_name")}
                    className={props.classes.textfield}
                    inputProps={{ maxLength: 25 }}
                  />
                  <br />
                  <TextField
                    name="company"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.company")}
                    className={props.classes.textfield}
                    inputProps={{ maxLength: 50 }}
                  />
                  <br />
                  <TextField
                    type="tel"
                    name="phoneNumber"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.phone")}
                    className={props.classes.textfield}
                    inputProps={{ maxLength: 25 }}
                  />
                  <br />
                  <TextField
                    required
                    type="email"
                    name="email"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.email")}
                    className={props.classes.textfield}
                    inputProps={{ maxLength: 50 }}
                  />
                  <br />
                  <TextField
                    required
                    name="password"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.password")}
                    className={props.classes.textfield}
                    type="password"
                    inputProps={{ maxLength: 200 }}
                  />
                  <br />
                  <TextField
                    required
                    name="repeatPassword"
                    variant="outlined"
                    size="small"
                    onChange={props.handleFormChange}
                    label={t("signup.confirm_password")}
                    className={props.classes.textfield}
                    type="password"
                    inputProps={{ maxLength: 200 }}
                  />
                  <br />
                  <FormControlLabel
                    style={{
                      marginLeft: 10,
                      marginBottom: 14,
                      marginRight: 0,
                    }}
                    control={
                      <Checkbox
                        style={{ color: "white", marginRight: 0 }}
                        name="hasAcceptedTerms"
                        onChange={props.handleFormChange}
                        color="primary"
                      />
                    }
                    label={
                      <div style={{ fontSize: 13 }}>
                        {t("signup.accept_terms_one")}{" "}
                        <a
                          href="https://landingzone.fr/cgu/"
                          className={props.classes.acceptLink}
                          target="_blank"
                          rel="noopener"
                        >
                          {t("signup.terms")}
                        </a>{" "}
                        {t("signup.accept_terms_two")}{" "}
                        <a
                          href="https://landingzone.fr/politique-de-confidentialite/"
                          className={props.classes.acceptLink}
                          target="_blank"
                          rel="noopener"
                        >
                          {t("signup.privacy")}
                        </a>
                      </div>
                    }
                  />
                  <br />
                  <div
                    style={{
                      color: props.state.isValidMinLength ? "green" : "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 16,
                    }}
                  >
                    {props.state.isValidMinLength ? <Check /> : <Close />}
                    {props.passwordCriteria.minLength.message}
                  </div>
                  <div
                    style={{
                      color: props.state.isValidSpecialChar ? "green" : "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 16,
                    }}
                  >
                    {props.state.isValidSpecialChar ? <Check /> : <Close />}
                    {props.passwordCriteria.specialChar.message}
                  </div>
                  <div
                    style={{
                      color: props.state.isValidNumber ? "green" : "red",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 16,
                    }}
                  >
                    {props.state.isValidNumber ? <Check /> : <Close />}
                    {props.passwordCriteria.number.message}
                  </div>
                  <Button
                    variant="contained"
                    type="submit"
                    className={props.classes.validateForm}
                  >
                    {t("signup.submit")}
                  </Button>
                  {props.state.showError ? (
                    <Alert severity="error">{props.state.errorMessage}</Alert>
                  ) : (
                    <div />
                  )}
                  {props.state.showSuccess ? (
                    <Alert severity="success">
                      {props.state.successMessage}
                    </Alert>
                  ) : (
                    <div />
                  )}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography className={props.classes.orLogin}>
                      {t("signup.or")}
                    </Typography>
                    <Divider
                      style={{ backgroundColor: "#768FDD" }}
                      className={props.classes.divider}
                    ></Divider>{" "}
                  </Box>
                  {/* <Button
                    variant="outlined"
                    href={
                      apiHost +
                      "/user/signinFacebook?redirectToSelectedPack=" +
                      props.state.redirectToSelectedPack
                    }
                    className={props.classes.facebookButton}
                  >
                    Se connecter avec Facebook
                  </Button> */}
                  <Button
                    variant="outlined"
                    className={props.classes.googleButton}
                    // href={
                    //   apiHost +
                    //   "/user/signinGoogle?redirectToSelectedPack=" +
                    //   props.state.redirectToSelectedPack
                    // }
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        apiHost +
                        "/user/signinGoogle?redirectToSelectedPack=" +
                        props.state.redirectToSelectedPack;
                    }}
                    startIcon={
                      <img
                        src="/images/google-icon.png"
                        alt="Google Logo"
                        style={{ width: 24, height: 24 }} // Adjust the size as needed
                      />
                    }
                  >
                    {t("signup.signin_google")}
                  </Button>
                  <br />
                  <Link className={props.classes.passwordForgotten} to="/login">
                    {t("signup.already_have_account")}
                  </Link>
                </div>
              </div>
            </form>
          </Box>

          <br />
        </div>
      </Box>
    </>
  );
};

export default SignupView;
